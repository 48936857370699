/*
  #74B7FF
  #4A9AFF
  #2684FC
*/

.App {
  text-align: center;
}

.App-logo {
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  z-index: 1000;
}

.gradient {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40vh; /* Adjust the height as needed */
  background: linear-gradient(to top, #74B7FF, #ffffff);
  pointer-events: none;
}

.App-header {
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #006184;
}

.google-signin-button {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translateX(-50%);
}

.error-message {
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 18px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 97px;
}

.App-body {
  /*min-height: 100vh;*/
  display: flex;
  flex-direction: column;
  /*font-size: calc(10px + 2vmin);*/
  color: white;
  overflow-x: auto;
  min-height: 480px;
}

.name {
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
  cursor: pointer;
  font-weight: bold;
  border-radius: 3px;
  margin-top: 1px;
  margin-bottom: 1px;
  padding-left: 2px;
  padding-right: 2px;
}

.name:hover {
  background-color: #F6F7F8;
}

.member-card {
  border-radius: 8px;
  padding: 2px;
  margin: 4px;
  width: 210px;
  height: 290px;
  color: black;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.zoomed-out .member-card {
  width: 150px;
  height: 200px;
}

.member-card .three-dots {
  opacity: 0;
  transition: opacity 0.2s;
}

.member-card:hover .three-dots,
.member-card .three-dots:focus-within,
.member-card .three-dots[aria-expanded="true"] {
  opacity: 1;
}

.empty-state {
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 500px;
  font-size: 20px;
}

.members {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.member-photo {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: cover;
  object-position: center;
}

.legend {
  position: sticky;
  top: 0;
  color: #525866;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 48px;
  width: 100%;
  font-size: 16px;
  z-index: 2000;
  padding-top: 8px;
  padding-bottom: 8px;
  border-top: 1px solid #525866;
  border-bottom: 1px solid #525866;
}

@media screen and (max-width: 768px) {
  .legend {
    display: none;
  }
}

.legend-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.welcome {
  font-size: 24px;
  color: #0E121B;
}

.add-card {
  border: 3px solid #B0B5C0;
  border-radius: 8px;
  padding: 2px;
  margin: 4px;
  background-color: white;
  color: black;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 210px;
  height: 290px;
}

.zoomed-out .add-card {
  width: 150px;
  height: 200px;
}

.add-card form {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.cta {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 3px;
  transition: all 0.3s ease;
  padding: 2px 2px;
  cursor: pointer;
}

.cta:not(:disabled):hover {
  background-color: #f0f0f0;
  border-color: #999;
}

.cta:disabled {
  cursor: default;
}

.tabs {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.tab {
  height: 20px;
}

.table-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 95%;
  max-height: 95%;
  overflow-y: auto;
}

.organization-section {
  min-width: calc(100% - 16px);
  border: 0.5px solid #B0B5C0;
  border-radius: 8px;
  margin: 8px 8px 16px 8px;
}

.organization-section h2 {
  width: 100%;
  color: #525866;
  background-color: #CACFD8;
  padding-top: 4px;
  padding-bottom: 4px;
  margin: 0;
  font-size: 24px;
  border-radius: 8px 8px 0 0;
}

.organization-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-top: 8px;
  padding-bottom: 8px;
  width: calc(100% - 16px);
  min-height: 215px;
  margin-left: 8px;
  margin-right: 8px;
}

.zoomed-out .organization-content {
  min-height: 0px;
}

.sub-organizations-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.sub-organization-section {
  margin: 8px;
  border: 0.5px solid #B0B5C0;
  border-radius: 8px;
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.1), 0 2px 8px rgba(0, 0, 0, 0.1);
}

.sub-organization-section h3 {
  border-radius: 8px 8px 0 0;
  color: #525866;
  background-color: #CACFD8;
  padding: 2px;
  margin: 0;
  font-size: 20px;
}

.sub-organization-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 8px;
  min-width: 190px;
  min-height: 215px;
}

.roommates-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  margin: 20px;
  gap: 20px;
}

.roommates-section {
  border: 0.5px solid #CACFD8;
  border-radius: 8px;
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.1), 0 2px 8px rgba(0, 0, 0, 0.1);
}

.roommates-section h2 {
  font-size: 16px;
  color: #525866;
  padding: 4px;
  background-color: #CACFD8;
  border-radius: 6px 6px 0 0;
}

.ministering-companionship {
  overflow-x: auto;
  white-space: nowrap;
  margin: 20px;
}

.zoomed-out .sub-organization-content {
  min-height: 197px;
}

.loading-spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24rem;
}

.loading-spinner {
  font-size: 1.875rem;
  color: #d1d5db;
  animation: spin 1s linear infinite;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.notification {
  position: fixed;
  top: 10px;
  right: 10px;
  height: 40px;
  color: black;
  border: 2px solid black;
  padding: 10px 20px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1001;
  max-width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /*box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);*/
}

.notification .close-btn {
  background: none;
  border: none;
  color: #721c24;
  font-size: 20px;
  cursor: pointer;
  margin-left: 10px;
}

.tagline {
  display: block;
  margin-bottom: 10px;
}

.comparison {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.animated-strikethrough {
  position: relative;
  color: #888;
  font-style: italic;
  margin-bottom: 5px;
}

.animated-strikethrough::after {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  width: 0;
  height: 1px;
  background-color: #888;
  animation: strikethrough 1s ease-out 0.5s forwards;
}

.animated-highlight {
  position: relative;
  overflow: hidden;
}

.animated-highlight::after {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to right, 
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  animation: shine 1s ease-out 2s forwards;
}

@keyframes strikethrough {
  to { width: 100%; }
}

@keyframes shine {
  100% { left: 100%; }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  width: 80%;
  max-width: 500px;
}

.modal-content h2 {
  margin-top: 0;
}

.modal-content textarea {
  width: 100%;
  margin-bottom: 10px;
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.menu-item {
  cursor: pointer;
  outline: none;
  color: #6B7280;
  margin-right: 15px;
  font-size: 15px;
}

.menu-item.selected {
  color: #2684FC;
  border-bottom: 2px solid #2684FC;
}

.dropdown-content {
  background-color: white;
  color: black;
  margin: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.dropdown-item {
  padding: 8px;
  cursor: pointer;
  border-radius: 3px;
  text-align: left;
  outline: none;
  display: flex;
  align-items: center;
}

.dropdown-item:hover {
  background-color: #e0e0e0;
}

.dropdown-item.selected {
  border-radius: 12px;
}

.dropdown-item:not(:last-child) {
  border-bottom: 1px solid #e2e8f0;
}

.dropdown-item:disabled {
  background-color: #e0e0e0;
  color: #a0a0a0;
  cursor: default;
}

.dropdown-item:disabled:hover {
  background-color: #e0e0e0;
  color: #a0a0a0;
  cursor: default;
}

.dropdown-item[disabled] {
  background-color: #e0e0e0;
  color: #a0a0a0;
  cursor: default;
}

.dropdown-item[disabled]:hover {
  background-color: #e0e0e0;
  color: #a0a0a0;
  cursor: default;
}

.note-item {
  width: 90%;
  position: relative;
  display: flex;
  border-top: 1px solid #eee;
}

.close-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
  color: #333;
}

/* ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 5px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(0,0,0,.5);
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
} */

.note-item .delete-icon {
  cursor: pointer;
  visibility: hidden;
  opacity: 0;
}

.note-item:hover .delete-icon {
  visibility: visible;
  opacity: 1;
}

.confirm-delete-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 4000;
}

.confirm-delete-modal button {
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.confirm-delete-modal button:first-child {
  background-color: #f1f3f4;
  color: #202124;
}

.confirm-delete-modal button:last-child {
  background-color: #ea4335  ;
  color: white;
}

.confirm-delete-modal button:hover {
  opacity: 0.8;
}

.download-csv-button {
  font-size: 20px;
  color: #99A0AE;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 4px;
  outline: none;
  background-color: transparent;
  padding: 5px;
  position: relative;
  bottom: 1px;
}

.download-csv-button:hover {
  color: #525866;
}

.release-confirmation-popover {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 4000;
  width: 80%;
  max-width: 300px;
}

.release-confirmation-popover button {
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.release-confirmation-popover button:first-child {
  background-color: #f1f3f4;
  color: #202124;
}

.release-confirmation-popover button:last-child {
  background-color: #011738;
  color: white;
}

.release-confirmation-popover button:hover {
  opacity: 0.8;
}

.overall-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding: 16px;
  height: 65px;
}

@media screen and (max-width: 768px) {
  .overall-container {
    display: none;
  }
}

.overall-box {
  width: 175px;
  height: 65px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
  border-radius: 8px;
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.1), 0 2px 8px rgba(0, 0, 0, 0.1);
}

.overall-label {
  position: absolute;
  bottom: 5px;
  left: 5px;
  font-size: 12px;
  font-weight: 600;
  color: #B0B5C0;
}

.overall-number {
  position: absolute;
  top: 40%;
  left: 50%;
  font-size: 20px;
  font-weight: 600;
  color: #0E121B;
  transform: translate(-50%, -50%);
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10000;
  width: 410px;
  max-height: 660px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 18px;
  margin-right: -18px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 2900;
}

.side-drawer {
  position: fixed;
  top: 0;
  height: 100%;
  background-color: #fff;
  box-shadow: 1px 0 4px rgba(0, 0, 0, 0.3);
  z-index: 3000;
  transition: left 0.1s ease;
  display: flex;
  flex-direction: column;
}

.side-drawer.en {
  left: -250px;
  width: 250px;
}

.side-drawer.es {
  left: -280px;
  width: 280px;
}

.side-drawer.open {
  left: 0;
}

.drawer-content {
  padding: 20px;
}

.close-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  background: none;
  border: none;
  cursor: pointer;
}

@media (max-width: 767px) {
  .hide-on-small-screens {
    display: none !important;
  }
}

button {
  cursor: pointer;
}

button:disabled {
  cursor: default;
}

.communication-icon {
  color: #808080;
  transition: color 0.2s ease;
}

.communication-icon:hover {
  color: black;
}

.flashcard {
  color: black;
  border: 3px solid #B0B5C0;
  border-radius: 8px;
  position: relative;
  width: 300px;
  height: 300px;
  cursor: pointer;
  transform-style: preserve-3d;
  transition: transform 0.6s;
}

.flashcard.transitioning {
  transition: transform 0.0s;
}

.flashcard.flipped {
  transform: rotateX(180deg);
}

.flashcard-front,
.flashcard-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.flashcard-front img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.flashcard-back {
  transform: rotateX(180deg);
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-center {
  text-align: center;
}

.name-cell:hover .tag-icon {
  display: block !important;
}

.container {
  padding: 20px;
  color: black;
}

.page-preview {
  width: 210mm;  /* A4 width */
  min-height: 297mm; /* A4 height */
  margin: 20px auto;
  padding: 20px;
  color: black;
  background: white;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

.download-button {
  padding: 10px 20px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.download-button:hover {
  background: #0056b3;
}

th:hover .gear-icon {
  opacity: 1 !important;
}

.flashcard-button:hover {
  background: #F6F7F8 !important;
}

.skeleton-container {
  width: 100%;
  padding: 32px;
  margin: 0;
  box-sizing: border-box;
}

.skeleton-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, 160px);
  gap: 16px;
  width: 100%;
}

.skeleton-header {
  width: calc(100% - 16px);
  height: 50px;
  background-color: #EEF0F4;
  border-radius: 8px 8px 0 0;
  margin: 8px;
  position: relative;
  overflow: hidden;
}

.skeleton-card {
  width: 160px;
  height: 210px;
  background-color: #EEF0F4;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.skeleton-rows-container {
  width: calc(100% - 32px);
  margin: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.skeleton-row {
  height: 48px;
  background-color: #EEF0F4;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}

.shimmer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.4),
    transparent
  );
  animation: shimmer 2s infinite;
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.message-template-shimmer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.6),
    transparent
  );
  animation: shimmer 2s infinite;
}

@keyframes message-template-shimmer {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.template-menu {
  display: flex;
  flex-direction: row;
  margin-left: 16px;
}

.template-menu button {
  color: #99A0AE;
  background: none;
  border: none;
  border-bottom: 1px solid #CACFD8;
  padding-left: 24px;
  padding-right: 24px;
  text-align: left;
  cursor: pointer;
  margin-bottom: 8px;
  transition: all 0.2s ease;
  font-size: 16px;
}

.template-menu button:hover {
  color: #525866;
  border-bottom-color: #99A0AE;
}

.template-menu button.active {
  color: #0e121b;
  border-bottom: 2px solid #0e121b;
  font-weight: 500;
}

.interview-time-button {
  margin: 5px;
  padding: 10px;
  background-color: #f0f0f0;
  border: none;
  cursor: pointer;
}

.interview-time-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* .modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
} */

/* .modal-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.modal-buttons button {
  margin-left: 10px;
} */